




























































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { TraineeImport } from '@/api/interfaces/traineeimport'
import { mapMutations, mapState } from 'vuex'
import { Dictionary } from 'vue-router/types/router'

export default defineComponent({
  name: 'ListImport',
  props: {
    listTraineeImport: {
      type: Array,
      required: true,
    },
    dialogImport: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    showColumnListTraineeImported: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      dialog: false,
    }
  },
  created () {
    this.dialog = this.dialogImport
  },
  watch: {
    dialogImport (newVal) {
      this.dialog = newVal
    },
  },
  computed: {
    ...mapState('import', {
      importCurrent: 'currentImport',
    }),
    importPendingOrInProgress (): boolean {
      return (
        (this.importPending as boolean) || (this.importInProgress as boolean)
      )
    },
    importPending (): boolean {
      return (
        (this.importCurrent as TraineeImport) &&
        (this.importCurrent as TraineeImport).status === 'PENDING'
      )
    },
    importInProgress (): boolean {
      return (
        (this.importCurrent as TraineeImport) &&
        (this.importCurrent as TraineeImport).status === 'IN_PROGRESS'
      )
    },
    importSuccess (): boolean {
      if (this.importCurrent) {
        return !this.importPendingOrInProgress && (this.importCurrent as TraineeImport).currentTrainingImported > 0
      } else {
        return false
      }
    },
    importFailed (): boolean {
      if (this.importCurrent) {
        return !this.importPendingOrInProgress && (this.importCurrent as TraineeImport).currentTrainingImported === 0
      } else {
        return false
      }
    },
    pourcentageImport (): number {
      let pourcentageImport = 0
      if (this.importCurrent) {
        const importCurrent: TraineeImport = this.importCurrent as TraineeImport
        if (
          importCurrent.totalTraining !== 0 &&
          importCurrent.status !== 'PENDING'
        ) {
          pourcentageImport = Math.round(
            (importCurrent.currentRow * 100) /
              importCurrent.totalTraining,
          )
        }
      }
      return pourcentageImport
    },
  },
  methods: {
    ...mapMutations('import', {
      setCurrentImport: 'setCurrentImport',
    }),
    showProgress (traineeImport: TraineeImport) {
      this.setCurrentImport(traineeImport)
      this.$emit('open-dialog')
    },
    displayStatus (traineeImport: TraineeImport) {
      switch (traineeImport.status) {
        case 'ERROR':
          return this.$t('import.list.statusError')
        case 'PENDING':
          return this.$t('import.list.statusPending')
        case 'FINISH':
        case 'WARNING':
          return this.$t('import.list.statusFinish')
        case 'IN_PROGRESS': {
          let pourcentage = 0
          if (
            traineeImport.totalTraining &&
            traineeImport.currentRow !== undefined
          ) {
            pourcentage = Math.round(
              (traineeImport.currentRow * 100) /
                traineeImport.totalTraining,
            )
          }
          return this.$t('import.list.statusInProgress', { pourcentage })
        }
      }
    },
    isNotFinished (traineeImport:TraineeImport): boolean {
      return traineeImport.status !== 'FINISH' && traineeImport.status !== 'WARNING'
    },
    disableLinkToTrainees (traineeImport:TraineeImport): boolean {
      return this.isNotFinished(traineeImport) || traineeImport.currentTrainingImported === 0
    },
    showImportTrainee (traineeImport: TraineeImport, alerts: boolean) {
      const query: Dictionary<string | (string | null)[] | null | undefined> = { traineeImport: traineeImport.id as string | undefined }
      if (alerts) {
        query.status = [this.statusAlertImportIri as string]
      }
      this.$router.push({ name: 'Trainee List', query: query })
    },
  },
})
